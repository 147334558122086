<template>
    <div>
        <app-layout>
            <template v-slot:header>
                <Header :title="$t('turkmer_admin_page_title')+ ' | '"
                        @filter-div-status="datatable.filterStatus = $event"
                        :other-button="{show:true,title:$t('transfer_applications'),emit:'sendRegister'}"
                        @sendRegister="sendRegister">
                    <template v-slot:columns>
                        <div class="mb-1" v-for="(column, key) in datatable.columns" :key="key">
                            <b-form-checkbox :id="'checkbox-' + key" :name="'checkbox-' + key" :value="false"
                                             :unchecked-value="true" v-model="column.hidden"
                                             v-if="column.field != 'buttons'">
                                {{ column.label }}
                            </b-form-checkbox>
                        </div>
                    </template>
                </Header>
            </template>
            <template v-slot:header-mobile>
                <HeaderMobile :title="$t('turkmer_admin_page_title')">
                    <template v-slot:columns>
                        <div class="mb-1" v-for="(column, key) in datatable.columns" :key="key">
                            <b-form-checkbox :id="'checkbox-' + key" :name="'checkbox-' + key" :value="false"
                                             :unchecked-value="true" v-model="column.hidden"
                                             v-if="column.field != 'buttons'">
                                {{ column.label }}
                            </b-form-checkbox>
                        </div>
                    </template>
                </HeaderMobile>
            </template>
            <datatable-filter v-show="datatable.filterStatus" @filter="filter" @filterClear="filterClear"
                              @exportExcel="excelExport"
                              :export-excel="checkPermission('turkmerapplication_excelexport')">
                <b-row>
                    <b-col sm="6" md="3">
                        <b-form-group :label="$t('passport_number')">
                            <b-form-input type="text" size="sm" v-model="datatable.queryParams.filter.passport_number"
                            ></b-form-input>
                        </b-form-group>
                    </b-col>

                    <b-col sm="6" md="3">
                        <b-form-group :label="$t('tc_no')">
                            <b-form-input type="text" size="sm" v-model="datatable.queryParams.filter.national_id"
                                          v-check-min-max-value></b-form-input>
                        </b-form-group>
                    </b-col>

                    <b-col sm="6" md="3">
                        <b-form-group :label="$t('name')">
                            <b-form-input
                                v-model="datatable.queryParams.filter.name"
                            ></b-form-input>
                        </b-form-group>
                    </b-col>


                    <b-col sm="6" md="3">
                        <b-form-group :label="$t('surname')">
                            <b-form-input
                                v-model="datatable.queryParams.filter.surname"
                            ></b-form-input>
                        </b-form-group>
                    </b-col>


                    <!--                    <b-col sm="6" md="3">-->
                    <!--                        <b-form-group :label="$t('academic_year')">-->
                    <!--                            <academic-years-selectbox-->
                    <!--                                v-model="datatable.queryParams.filter.academic_year"></academic-years-selectbox>-->
                    <!--                        </b-form-group>-->
                    <!--                    </b-col>-->


                    <b-col sm="6" md="3">
                        <b-form-group :label="$t('program')">
                            <parameter-selectbox
                                code="turkmer_program_code"
                                v-model="datatable.queryParams.filter.program_code"
                            />
                        </b-form-group>
                    </b-col>


                    <b-col sm="6" md="3">
                        <b-form-group :label="$t('status')">
                            <multiselect
                                v-model="datatable.queryParams.filter.status"
                                :options="statusOptions"
                                label="text"
                                track-by="value"
                                :multiple="false"
                                :select-label="''"
                                :selected-label="''"
                                :deselect-label="''"
                                :placeholder="$t('select')"
                                :searchable="true">
                                <span slot="noOptions">
                                    {{ $t('no_options') }}
                                </span>
                            </multiselect>
                        </b-form-group>
                    </b-col>

                </b-row>
            </datatable-filter>
            <datatable :isLoading.sync="datatable.isLoading" :columns="datatable.columns" :rows="datatable.rows"
                       :total="datatable.total" :queryParams="datatable.queryParams" @on-page-change="onPageChange"
                       @on-sort-change="onSortChange" @on-per-page-change="onPerPageChange"
                       v-show="datatable.showTable">
            </datatable>
        </app-layout>
    </div>
</template>
<script>
// Template
import AppLayout from "@/layouts/AppLayout"
import Header from "@/layouts/AppLayout/Header"
import HeaderMobile from "@/layouts/AppLayout/HeaderMobile"
// Components
import DatatableFilter from "@/components/datatable/DatatableFilter";
import Datatable from "@/components/datatable/Datatable";
import CommonModal from "@/components/elements/CommonModal";
import FacultySelectbox from "@/components/interactive-fields/FacultySelectbox";
import DepartmentSelectbox from "@/components/interactive-fields/DepartmentSelectbox";
import ParameterSelectbox from "@/components/interactive-fields/ParameterSelectbox";
import ProgramSelectbox from "@/components/interactive-fields/ProgramSelectbox";
import StatusSelectbox from "@/components/interactive-fields/StatusSelectbox";
// Pages
// Services
import TurkmerApplicationService from "../../../../services/TurkmerApplicationService";

// Others
import qs from 'qs'
import TurkmerApplicationProgramSelectBox from "@/components/interactive-fields/TurkmerApplicationProgramSelectBox.vue";
import LmsContentService from "@/services/LmsContentService";

export default {
    components: {
        TurkmerApplicationProgramSelectBox,
        AppLayout,
        Header,
        HeaderMobile,
        DatatableFilter,
        Datatable,
        CommonModal,
        FacultySelectbox,
        DepartmentSelectbox,
        ParameterSelectbox,
        ProgramSelectbox,
        StatusSelectbox,
    },
    metaInfo() {
        return {
            title: this.$t('turkmer_admin_page_title')
        }
    },
    data() {
        return {
            selected: null,
            statusOptions: [
                {
                    text: this.$t('approved'), value: 'approved'
                },
                {
                    text: this.$t('rejected'), value: 'rejected'
                }

            ],
            datatable: {
                isLoading: false,
                columns: [
                    {
                        label: '',
                        field: 'buttons',
                        html: true,
                        sortable: false,
                        tdClass: 'p-0 text-center w-40 align-middle',
                        buttons: [
                            {
                                text: this.$t('update'),
                                class: 'ri-search-eye-line align-middle top-minus-1 mr-3 text-muted',
                                permission: "studentprogram_excelexport",
                                callback: (row) => {
                                    this.showApplication(row.id)
                                }
                            },
                            // {
                            //     text: this.$t('show'),
                            //     class: 'ri-search-eye-line align-middle top-minus-1 mr-3 text-muted',
                            //     permission: "studentprogram_excelexport",
                            //     callback: (row) => {
                            //         this.showApplication(row.id)
                            //     }
                            // },
                        ]
                    },
                    {
                        label: this.toUpperCase('id'),
                        field: 'id',
                        hidden: true,
                    },
                    {
                        label: this.toUpperCase('passport_number'),
                        field: 'passport_number',
                        hidden: false,
                    },
                    {
                        label: this.toUpperCase('tc_no'),
                        field: 'national_id',
                        hidden: false,
                        formatFn: (value) => {
                            return value == 'null' || value == null ? '' : value;
                        },
                    },
                    {
                        label: this.toUpperCase('name'),
                        field: 'name',
                        hidden: false,
                    },
                    {
                        label: this.toUpperCase('surname'),
                        field: 'surname',
                        hidden: false,
                        sortable: false
                    },
                    {
                        label: this.toUpperCase('type_of_program'),
                        field: 'program_type',
                        hidden: false,
                        sortable: false
                    },
                    {
                        label: this.toUpperCase('program'),
                        field: 'program_code',
                        hidden: false,
                        sortable: false
                    },
                    {
                        label: this.toUpperCase('language_text'),
                        field: 'before_turkish_certificate_status',
                        hidden: false,
                        sortable: false,
                        formatFn: (value) => {
                            return value ? this.getApproveText(value) : ''
                        }
                    },
                    {
                        label: this.toUpperCase('status'),
                        field: 'status',
                        hidden: false,
                        sortable: false,
                        formatFn: (value) => {
                            return value ? this.getTurkmerApplicationStatusText(value) : ''
                        }
                    },
                ],
                rows: [],
                total: 0,
                filterStatus: true,
                showTable: false,
                queryParams: {
                    filter: {},
                    sort: '-id',
                    page: 1,
                    limit: 20
                }
            },
            formProcess: null,
            formId: null,
            form: {},
        }
    },
    created() {
        this.filterSet();
        this.formClear();
    },
    methods: {
        excelExport() {
            const config = {
                params: {
                    ...this.datatable.queryParams
                },
                paramsSerializer: (params) => qs.stringify(params, {encode: false})
            };
            return TurkmerApplicationService.excelExport(config)
                .then(response => {
                    this.$toast.success(this.$t('api.' + response.data.message))
                    this.$router.push('/job/logs/detail/' + response.data.data.log_id)

                }).catch(err => {
                    this.showErrors(err)
                })

        },
        filterSet() {
            this.datatable.queryParams.filter = {
                national_id: null,
                passport_number: null,
                name: null,
                surname: null,
                program_code: null,
                status: null,
            }
        },
        filterClear() {
            this.filterSet()
            this.getRows();
        },
        filter() {
            this.datatable.queryParams.page = 1;
            this.getRows();
        },
        onPageChange(params) {
            this.datatable.queryParams.page = params.currentPage;
            this.getRows();
        },
        onPerPageChange(params) {
            this.datatable.queryParams.limit = params.currentPerPage;
            this.datatable.queryParams.page = 1;
            this.getRows();
        },
        changeColumn(key) {
            this.datatable.columns[key].hidden = this.datatable.columns[key].hidden == true ? false : true;
        },
        onSortChange(params) {
            const sortType = params[0].type == 'desc' ? '-' : '';
            this.datatable.queryParams.sort = sortType + params[0].field
            this.getRows();
        },
        getRows() {
            this.datatable.showTable = true;
            this.datatable.isLoading = true;
            const config = {
                params: {
                    ...this.datatable.queryParams
                },
                paramsSerializer: (params) => qs.stringify(params, {encode: false})
            };

            return TurkmerApplicationService.getAll(config)
                .then(response => {

                    this.datatable.rows = response.data.data
                    this.datatable.isLoading = false

                }).catch(err => {
                    this.showErrors(err)
                })

        },

        // Clear
        formClear() {
            this.formId = null
            this.formProcess = null
        },
        showApplication(id) {
            this.$router.push('/turkmer/application/' + id)
        },
        sendRegister() {
            this.$swal
                .fire({
                    text: this.$t('are_you_sure_to_approve'),
                    showCancelButton: true,
                    confirmButtonText: this.$t('yes'),
                    cancelButtonText: this.$t('no')
                })
                .then((result) => {
                    if (result.isConfirmed) {
                        TurkmerApplicationService.sendRegister()
                            .then(response => {
                                this.$toast.success(this.$t('api.' + response.data.message));
                            })
                            .catch(error => {
                                this.$toast.error(this.$t('api.' + error.data.message));
                            });
                    }
                })
        },

    }
}
</script>

